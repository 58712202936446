import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react'; // Импортируем useState для управления состоянием
import '../css/Home.css';

const Header = props => {
    const [showMenu, setShowMenu] = useState(false); // Состояние для отображения/скрытия меню

    const visMenu = () => {
        props.menu();
    }

    const toggleMenu = () => {
        setShowMenu(!showMenu); // Переключаем состояние меню
    }

    const isKok = props.isKok;

    const navigate = useNavigate();

    return (
        <nav className="cont">
            <div className='contHome'>
                <div onClick={() => { navigate(-1) }} className="addBike" style={{ height: "15px", paddingLeft: "10px", paddingRight: "10px", paddingTop: "3px" }}>Back</div>
                <Link to='/' className="addBike" style={{ width: "20px", height: "20px", borderRadius: "50%" }}>YB</Link>
                
                {/* Кнопка "Menu" отображается только если !isKok */}
                {!isKok && (
                    <button className="addBike" style={{height:"35px", width:"50px", fontSize:"16px"}} onClick={toggleMenu}>Menu</button>
                )}

                {/* Скрытые кнопки, которые отображаются при нажатии на "Menu" */}
                {!isKok && showMenu && (
                    <div className="blockMenu" onClick={toggleMenu}>
                        <Link to='/allbooking' className="btnMenuHeader" >Клиенты</Link>
                        <Link to='/reservations' className="btnMenuHeader" >Бронирования</Link>
                        <Link to='/allcount' className="btnMenuHeader" >Сводка отчеты</Link>
                        <Link to='/addbike' className="btnMenuHeader" >Добавить мопед</Link>
                        <Link to='/warehouse' className="btnMenuHeader" style={{marginBottom:"0px"}}>Склад</Link>
                    </div>
                )}

                <Link to='/price' className="addBike">Prices</Link>
                {!props.isKolcov && <Link to='/totalcontrol' className="addBike">Reporting</Link>}
                <button className="addBike" style={{ width: "30px", height: "30px", borderRadius: "50%" }} onClick={visMenu}>+</button>
            </div>
        </nav>
    )
}

export { Header }