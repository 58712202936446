import React, { useState } from "react";
import LoadingScreen from "./LoadingScreen";

const PullToRefresh = ({ children }) => {
  const [touchStartY, setTouchStartY] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isAtTop, setIsAtTop] = useState(false);
  const [isPinching, setIsPinching] = useState(false);

  const handleTouchStart = (e) => {
    if (e.touches.length > 1) {
      setIsPinching(true); // Фиксируем масштабирование
      return;
    }

    if (window.scrollY === 0) {
      setIsAtTop(true);
      setTouchStartY(e.touches[0].clientY);
    } else {
      setIsAtTop(false);
    }
  };

  const handleTouchMove = (e) => {
    if (isPinching || e.touches.length > 1) return; // Если был пинч, не обрабатываем свайп

    if (!isAtTop || touchStartY === null) return;

    const touchCurrentY = e.touches[0].clientY;
    const touchDeltaY = touchCurrentY - touchStartY;

    // Визуальная обратная связь (опционально)
  };

  const handleTouchEnd = (e) => {
    if (isPinching) {
      setTimeout(() => setIsPinching(false), 300); // Сбрасываем пинч с задержкой
      return;
    }

    if (!isAtTop || touchStartY === null) return;

    const touchEndY = e.changedTouches[0].clientY;
    const touchDeltaY = touchEndY - touchStartY;

    if (touchDeltaY > 1000 && !isRefreshing) {
      setIsRefreshing(true);

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }

    setTouchStartY(null);
    setIsAtTop(false);
  };

  return (
    <div
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {isRefreshing && <LoadingScreen />}
      {children}
    </div>
  );
};

export { PullToRefresh };
