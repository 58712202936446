
// import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { initializeApp } from 'firebase/app';
import {collection, addDoc, getFirestore} from 'firebase/firestore'; 
import '../css/AddBike.css'
import data from '../fields.json'
import { useState } from 'react'
import firebaseConfig from './firebaseConfig'
import { Link } from 'react-router-dom';
// import Block from './Block'

const AddBooking = props => {
    //Подключаемся к FireBase
    const app = initializeApp(firebaseConfig);
    // const storage = getStorage(app);
    const db = getFirestore(app);

    const [addBooking, setAddBooking] = useState(data.booking)
    const [backC, setBackC] = useState("white") 

    // const paste = () => {
    //   if (props.copyCustomer) {
    //     setBackC("#74b597")
    //     const countDate =  (new Date(new Date(props.copyCustomer.addBooking.dateEnd).getTime() + parseInt(props.copyCustomer.addBooking.days) * 24 * 60 * 60 * 1000+(7*60*60*1000))).toISOString().slice(0, 16)
    //     console.log(props.copyCustomer)
    //     setAddBooking(props.copyCustomer.addBooking)
    //     setAddBooking((prevState) => ({
    //       ...prevState,
    //       dateStart: props.copyCustomer.addBooking.dateEnd
    //     }))
    //     setAddBooking((prevState) => ({
    //       ...prevState,
    //       dateEnd: countDate
    //     }))
        
    //   }
    // }

    const paste = () => {
      const storedBooking = localStorage.getItem("booking");
  
      if (storedBooking) {
          const itemStorage = JSON.parse(storedBooking);
          console.log(itemStorage);
  
          setBackC("#74b597");
  
          // Получаем дату окончания предыдущего бронирования
          const prevDateEnd = new Date(itemStorage.addBooking.dateEnd);
  
          // Вычисляем новую дату окончания
          const countDate = new Date(prevDateEnd.getTime() + parseInt(itemStorage.addBooking.days) * 24 * 60 * 60 * 1000 + (7 * 60 * 60 * 1000))
              .toISOString()
              .slice(0, 16);
  
          // Устанавливаем новое состояние в одном вызове setAddBooking
          setAddBooking({
              ...itemStorage.addBooking,
              dateStart: itemStorage.addBooking.dateEnd,  // Новая дата старта = предыдущая дата окончания
              dateEnd: countDate,  // Новая дата окончания
          });
      } else {
          alert("Need copy information")
      }
  };
  

    
    

    //Функция изменения значения
    const handleChange = (event) => {
        setAddBooking((prevState) => ({
            ...prevState,
            id: props.id
          }));
        const { name, value } = event.target;
        if (name==="dateEnd"){
            const countDays = ((new Date(value)-new Date(addBooking.dateStart))/ (1000 * 60 * 60 * 24))
            setAddBooking((prevState) => ({
                ...prevState,
                [name]: value
              })
            )
              setAddBooking((prevState) => ({
                ...prevState,
                days: Math.floor(countDays)
              })
              )
        } else if (name==="days"){
            const countDate =  (new Date(new Date(addBooking.dateStart).getTime() + parseInt(value) * 24 * 60 * 60 * 1000+(7*60*60*1000))).toISOString().slice(0, 16)
            setAddBooking((prevState) => ({
                ...prevState,
                [name]: value
              })
            )
            // console.log(countDate)
              setAddBooking((prevState) => ({
                ...prevState,
                dateEnd: countDate
              })
              )
        } else if (name==="priceDay"){
          const calculateTotal = parseInt(value) * parseInt(addBooking.days)

          setAddBooking((prevState) => ({
             ...prevState,
             [name]: value,
             totalPrice: calculateTotal ? calculateTotal:''// Добавляем пересчет totalPrice
           })
         )

       } else {
               setAddBooking((prevState) => ({
                  ...prevState,
                  [name]: value,

                })
              )
            
            };
        }

    //Функция очистки всех полей в объекте создания документа
    const handleClear = () => {
    const clearedUser = Object.keys(addBooking).reduce((acc, key) => {
      acc[key] = '';
      return acc;
    }, {});
    setAddBooking(clearedUser);
    setBackC("white")
  };
        
    const handleSave = async () => {
      if (addBooking.depCurrency) {
        try {
            // Создаем новый документ в коллекции "users" с данными пользователя
      // eslint-disable-next-line
            const docRef = await addDoc(collection(db, props.user.booking), {
                addBooking
            });
            // Очищаем поля ввода после сохранения данных
            localStorage.removeItem("booking");
            handleClear()

            window.location.reload()
          } catch (error) {
            console.error('Ошибка при добавлении документа:', error);
          }
        } else {
          alert("Need to choose deposit currency");
        }
    }
// Для вычисления стоимости в зависимости от дней и цены
    const calculateTotalPrice = () => {
      const { priceDay, days } = addBooking;
      if (priceDay && days && priceDay !== '-') {
        return parseInt(priceDay) * parseInt(days);
      } else {
        return addBooking.totalPrice;
      }
    };
    return (
        <div className="contRedAdd">
          {/* <Block/> */}
            <div >
              <div>
                <div style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                  <h3 style={{margin:"0", marginBottom:"10px"}}>Add rent</h3>
                  <Link className="linkPrice" to="/price" target="_blank">Prices</Link>
                  <button onClick={handleClear} style={{width:"90px", height:"25px", fontSize:"16px",  borderRadius:"5px", cursor:"pointer", marginBottom:"5px", backgroundColor:"#b5a074"}}>Clear</button>
                  <button onClick={paste} style={{width:"90px", height:"25px", fontSize:"16px",  borderRadius:"5px", cursor:"pointer", marginBottom:"5px", backgroundColor: backC}} >Paste</button>
                </div>
                </div>
                <div className="contInput">
                     <p>Name customer:                    </p>
                    <input 
                                  name="fio"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={addBooking.fio}
                                  onChange={handleChange}
                        />

                    </div>
                    <div className="contInput">
                    <p>Passport number:</p>
                    <input 
                                  name="passport"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={addBooking.passport}
                                  onChange={handleChange}
                        />
                        
                    </div>
                    <div className="contInput">
                    <p>Date of birth:</p>
                    <input 
                                  name="datebirth"
                                  type='date'
                                  onWheel={(e) => e.target.blur()}
                                  value={addBooking.datebirth}
                                  onChange={handleChange}
                        />
                        
                    </div>
                    <div className="contInput">
                    <p>Code of issuing State:</p>
                    <input 
                                  name="codestate"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={addBooking.codestate}
                                  onChange={handleChange}
                        />
                        
                    </div>
                    <div className="contInput">
                    <p>Location:</p>
                    <input 
                                  name="place"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={addBooking.place}
                                  onChange={handleChange}
                                  placeholder="District / name hotel / room"
                        />
                        
                    </div>
                    <div className="contInput">
                    <p>Comment:</p>
                    <input 
                                  name="comment"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={addBooking.comment}
                                  onChange={handleChange}
                        />
                        
                    </div>
                    <div className="contInput">
                    <p>Phone number: </p>
                        <input 
                                  name="phone"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={addBooking.phone}
                                  onChange={handleChange}
                        />
                    
                    </div>
                    <div className="contInput">
                    <p>Telegram: </p>
                        <input 
                                  name="tg"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={addBooking.tg}
                                  onChange={handleChange}
                        />
                    
                    </div>
                    <div className="contInput">
                    <p>Helmets:</p>
                    <input 
                                  name="helmets"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={addBooking.helmets}
                                  onChange={handleChange}
                        />
                    
                    </div>
                    <div className="contInput">
                    <p>Date/time rent start:</p>
                    <input 
                                  name="dateStart"
                                  type='datetime-local'
                                  onWheel={(e) => e.target.blur()}
                                  value={addBooking.dateStart}
                                  onChange={handleChange}
                        />
                    
                    </div>
                    <div className="contInput">
                    <p>Date/time rent finish:</p>
                    <input 
                                  name="dateEnd"
                                  type='datetime-local'
                                  onWheel={(e) => e.target.blur()}
                                  value={addBooking.days?
                                    (new Date(new Date(addBooking.dateStart).getTime() + parseInt(addBooking.days) * 24 * 60 * 60 * 1000+(7*60*60*1000))).toISOString().slice(0, 16):
                                    addBooking.dateEnd
                                    }
                                  onChange={handleChange}
                        />
                    
                    </div>
                    <div className="contInput">
                    <p>Rent comment:</p>
                    <input 
                                  name="comRent"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={addBooking.comRent}
                                  onChange={handleChange}
                        />
                        
                    </div>

                    <div className="contInput">
                    <p>Rent days:</p>
                    <input 
                                  name="days"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={
                                    addBooking.dateEnd ? 
                                    (Math.floor((new Date(addBooking.dateEnd)-new Date(addBooking.dateStart))/ (1000 * 60 * 60 * 24))):
                                    addBooking.days}
                                  onChange={handleChange}
                        />
                    
                    </div>
                    <div className="contInput">
                    <p>Price day:</p>
                    <input 
                                  name="priceDay"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={addBooking.priceDay}
                                  onChange={handleChange}
                        />
                    
                    </div>
                    <div className="contInput">
                    <p>Price total:</p>
                    <input 
                                  name="totalPrice"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={addBooking.totalPrice !== '' ? addBooking.totalPrice : calculateTotalPrice()}
                                  onChange={handleChange}
                        />
                    
                    </div>
                    <div style={{display:"flex", width:"101.5%"}}>
                    <div className="contInput" style={{width:"80%"}}>
                    <p>Deposit:</p>
                    <input 
                                  style={{width:"62%", textAlign:"end", paddingRight:"10%", boxSizing:"border-box"}}
                                  name="deposit"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={addBooking.deposit}
                                  onChange={handleChange}
                        />
                    
                    </div>
                    <div className="contInput" style={{width:"25%"}}>
                        <select
                          style={{width:"100%", border:"none"}}
                          name="depCurrency"
                          defaultValue=""
                          onWheel={(e) => e.target.blur()}
                          value={addBooking.depCurrency || ""}
                          onChange={handleChange}
                        >
                          <option value="" disabled hidden>Choose currency</option>
                          <option value="baht">baht</option>
                          <option value="baht online">baht online</option>
                          <option value="rub online">rub online</option>
                          <option value="rub cash">rub cash</option>
                          <option value="usd">usd</option>
                          <option value="usdt (crypto)">usdt (crypto)</option>
                          <option value="euro">euro</option>
                          <option value="passport">passport</option>
                          <option value="other">other</option>
                        </select>
                      </div>
                      </div>
                    <div className="contInput">
                    <p>Delivery:</p>
                    <input 
                                  name="delivery"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={addBooking.delivery}
                                  onChange={handleChange}
                        />
                    
                    </div>
                    <div className="contInput" style={{backgroundColor:"#abbcff"}}>
                    <p>How find out:</p>
                    <select 
                                  name="sourceAdd"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={addBooking.sourceAdd}
                                  onChange={handleChange}
                        >
                          <option value="other">Other</option>
                          <option value="instagram">Instagram</option>
                          <option value="telegramm">Telegram</option>
                          <option value="vk">VK</option>
                          <option value="marketplace">Marketplace</option>
                          <option value="googleMaps">Google maps</option>
                          <option value="YandexMaps">Yandex maps</option>
                          <option value="advise">Advice</option>
                          <option value="webSite">Web site</option>
                          <option value="prolong">New contract</option>
                          <option value="instagram">Instagram</option>
                          <option value="agent">Agent</option>
                          <option value="ourcustomer">Our Customer</option>
                        </select>
                    
                    </div>
                    <div>
                        {/* <button>Распечатать договор</button> */}
                        <button className="redButton" onClick={handleSave}>Save</button>
                    </div>
                    
            </div>
        </div>
    )
}

export default AddBooking