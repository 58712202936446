import { useState } from 'react';
import { initializeApp } from 'firebase/app';
import { collection, addDoc, getFirestore, setDoc} from 'firebase/firestore'; 
import './AddExpenses.css';
import data from './fields.json';
import firebaseConfig from '../firebaseConfig';

const ControlAddExp = (props) => {
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);

    const [addExp, setAddExp] = useState(data.expenses);
    const [searchResults, setSearchResults] = useState([]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setAddExp((prevState) => ({
            ...prevState,
            // id: String(Date.now()).slice(-6),
            datewrite: String(Date.now()),
            [name]: value
        }));

        if (name === "bike") {
            const results = Object.values(props.allBikes).filter(bike => 
                bike.addBike.regNumber.includes(value)
            );
            setSearchResults(results);
        }
    };

    const handleSelectBike = (bike) => {
        setAddExp((prevState) => ({
            ...prevState,
            bikeArt: bike.addBike.id,
            bikeId: bike.id,
            bike: bike.addBike.brand + " " + bike.addBike.model + " (" + bike.addBike.regNumber+")"
        }));
        setSearchResults([]);
    };
    

    const handleClear = () => {
        const clearedUser = Object.keys(addExp).reduce((acc, key) => {
            acc[key] = '';
            return acc;
        }, {});
        setAddExp(clearedUser);
    };

    const handleSave = async () => {
        try {
            const docRef = await addDoc(collection(db, 'allexpenses'), addExp);
            
            // Обновляем созданный документ, добавляя его ID
            await setDoc(docRef, { ...addExp, id: docRef.id });
    
            handleClear();
            window.location.reload()
        } catch (error) {
            console.error('Ошибка при добавлении документа:', error);
        }
    };

    return (
        <div>
            <div className="contAddExp" onClick={()=> setSearchResults([])} style={{backgroundColor:"#c4c3d6"}}>
                <h3 style={{ margin: "0" }}>Add Expense</h3>

                <div className="contInputExp">
                    <p>Date:</p>
                    <input 
                        name="date"
                        type='date'
                        onWheel={(e) => e.target.blur()}
                        value={addExp.date}
                        onChange={handleChange}
                    />
                </div>
                <div className="contInputExp">
                    <p>Amount:</p>
                    <input 
                        name="amount"
                        type='text'
                        onWheel={(e) => e.target.blur()}
                        value={addExp.amount}
                        onChange={handleChange}
                    />
                </div>
                <div className="contInputExp">
                <p>Item expense:</p>
                    <select 
                        name="itemexp" 
                        value={addExp.itemexp} 
                        onChange={handleChange}
                    >ы
                        <option value="Other">Other</option>
                        <option value="Gas">Gas</option>
                        <option value="Taxi">Taxi</option>
                        <option value="Return deposit">Return deposit</option>
                        <option value="Return for rent">Return for rent</option>
                        <option value="Lazada">Lazada</option>
                    </select>
                </div>
                <div className="contInputExp">
                    <p>Comment:</p>
                    <input 
                        name="comment"
                        type='text'
                        onWheel={(e) => e.target.blur()}
                        value={addExp.comment}
                        onChange={handleChange}
                    />
                </div>
                <div className="contInputExp" style={{position:"relative"}}>
                    <p>Bike:</p>
                    <input 
                        name="bike"
                        type='text'
                        onWheel={(e) => e.target.blur()}
                        value={addExp.bike}
                        onChange={handleChange}
                    />
                    {searchResults.length > 0 && (
                        <div className="searchResults">
                            {searchResults.map((bike) => (
                                <div 
                                    key={bike.id} 
                                    className="searchItem" 
                                    onClick={() => handleSelectBike(bike)}
                                >
                                    {bike.addBike.brand} {bike.addBike.model} ({bike.addBike.regNumber})
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className="contInputExpCheck">
                    <p>Online:</p>
                    <input 
                        name="cash"
                        type="checkbox"
                        onWheel={(e) => e.target.blur()}
                        checked={addExp.cash} 
                        onChange={(e) => setAddExp(prev => ({ ...prev, cash: e.target.checked }))}
                        style={{ width: "25px", height: "25px" }}
                    />
                </div>

                <button style={{ cursor: "pointer" }} className="redButtonExp" onClick={handleSave}>
                    Save expense
                </button>
            </div>
        </div>
    );
};

export default ControlAddExp;
