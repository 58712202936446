import TotalCount from "./TotalCount"
import ControlExp from "./ControlExp"
import IcomControl from "./IcomControl"

const TotalControl = props => {
    return (
        <div>
            <TotalCount allExpenses={props.allExpenses} allIncome={props.allIncome} allBikes={props.allData} isKok={props.isKok}/>
            <ControlExp allExpenses={props.allExpenses} allBikes={props.allData} isKok={props.isKok}/>
            <IcomControl allIncome={props.allIncome} allBikes={props.allData} isKok={props.isKok}/>
        </div>

    )
}

export {TotalControl}