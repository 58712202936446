import React, { useState, useEffect } from "react";
import tableSet from "../image/tableSet.png";

const Dropdown = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  // Загружаем сохраненные данные из localStorage при первом рендере
  useEffect(() => {
    const storedItems = JSON.parse(localStorage.getItem("selectedDropdownItems")) || [];
    setSelectedItems(storedItems);
  }, []);

  // Функция переключения выпадающего списка
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Функция обработки клика по элементу списка
  const handleItemClick = (item) => {
    props.chLocal()
    let updatedItems;

    if (selectedItems.includes(item)) {
      // Удаляем элемент, если он уже выбран
      updatedItems = selectedItems.filter((selectedItem) => selectedItem !== item);
    } else {
      // Добавляем элемент, если его нет
      updatedItems = [...selectedItems, item];
    }

    setSelectedItems(updatedItems);
    localStorage.setItem("selectedDropdownItems", JSON.stringify(updatedItems));
  };

  // Проверка, выбран ли элемент
  const isItemSelected = (item) => selectedItems.includes(item);

  const menuItems = [
    "Bike name",
    "%",
    "Number",
    "Date mileage",
    "Km left change oil",
    "Insurance end",
    "Finish rent",
    "Name customer",
    "Deposit",
    "Comment",
    "Condition",
    "№ box",
  ];

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      {/* Иконка */}
      <button
        onClick={toggleDropdown}
        style={{
          background: "none",
          border: "none",
          cursor: "pointer",
          fontSize: "24px",
        }}
        aria-label="Toggle Dropdown"
      >
        <img src={tableSet} alt="-" style={{ width: "30px", height: "30px", marginLeft: "40px" }} />
      </button>

      {/* Выпадающий список */}
      {isOpen && (
        <ul
          style={{
            position: "absolute",
            width: "200px",
            top: "100%",
            right:"5%",
            margin: "0",
            padding: "10px",
            listStyle: "none",
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            borderRadius: "5px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            zIndex: 1000,
          }}
        >
          {menuItems.map((item) => (
            <li
              key={item}
              style={{
                padding: "5px 10px",
                cursor: "pointer",
                borderBottom: "1px solid grey",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              onClick={() => handleItemClick(item)}
            >
              <span>{item}</span>
              {/* Галочка, если элемент выбран */}
              {!isItemSelected(item) && <span>✔</span>}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
