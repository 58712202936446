import '../css/LoadingScreen.css'
import logo from '../image/rentLabel.PNG'

const LoadingScreen = () => {
    return (
        <div className='contLoading'>
            <img src={logo} alt='Loading...'/>
        </div>
    )
}

export default LoadingScreen