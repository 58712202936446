
// import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { initializeApp } from 'firebase/app';
import {doc, updateDoc, getFirestore, deleteDoc } from 'firebase/firestore'; // Firebase Modular SDK импорты
import '../css/AddBike.css'
import { useEffect, useState } from 'react'
import firebaseConfig from './firebaseConfig'
import Agreement from './Agreement.jsx'
import AgreementKok from './AgreementKok'
import AgreementKolcov from './AgreementKolcov'
import ModalDelBook from '../js/ModalDelBook'

const RedBooking = props => {
    //Подключаемся к FireBase
    const app = initializeApp(firebaseConfig);
    // const storage = getStorage(app);
    const db = getFirestore(app);

    const [redBooking, setRedBooking] = useState(null)
    // eslint-disable-next-line
    const [isKok, setIsKok] = useState(props.isKok)

    const [visModal, setVisModal] = useState(false)

    //Фон для кнопки
    const [backC, setBackS] = useState("white")

    useEffect(() => {
        setRedBooking(props.rent)
      }, [props]);

    const setModal = () => {
      setVisModal(!visModal)
    }
    //Функция изменения значения
    const handleChange = (event) => {
        const { name, value } = event.target;
        setRedBooking((prevData) => ({
          ...prevData,
          addBooking: {
            ...prevData.addBooking,
            [name]: value
          }
        }));
      };

  const copyC = () => {
    localStorage.setItem("booking", JSON.stringify(redBooking));
    console.log(redBooking)
    setBackS("#b5a074")
    setTimeout(()=>{
      setBackS("white")
    },1000)
  }
        
  const updateDocument = async () => {
    try {
      console.log("Кольцов или нет!!!!", props.isKolcov)
      const documentRef = doc(db, props.user.booking, props.rent.id);
      await updateDoc(documentRef, redBooking);
      window.location.reload()
    } catch (error) {
      console.error('Ошибка при обновлении документа:', error);
      return false;
    }
}

const deleteDocument = async () => {
    try {
      const documentRef = doc(db, props.user.booking, props.rent.id); 
      await deleteDoc(documentRef);
      setVisModal(false)
      window.location.reload();
    } catch (error) {
      console.error("Ошибка при удалении документа из базы данных", error);
    }
  };
    return (
        <div >
          {visModal&& <ModalDelBook deleteDocument={deleteDocument} setModal={setModal}/>}
            {redBooking&&
            <div className="contRedAdd">
              <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", marginBottom:"10px"}}>
                <h3 style={{margin:"0", marginBottom:"10px"}}>Show/red rent</h3>
                <button onClick={copyC} style={{width:"90px", height:"30px", fontWeight:"bold", fontSize:"16px", backgroundColor: backC, borderRadius:"5px", cursor:"pointer"}}>Copy</button>
                </div>
                <div className="contInput">
                     <p>Name customer:</p>
                    <input 
                                  name="fio"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={redBooking.addBooking.fio}
                                  onChange={handleChange}
                                  readOnly={isKok}
                        />
                    
                    </div>
                    <div className="contInput">
                        <p>Passport number:</p>
                    <input 
                                  name="passport"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={redBooking.addBooking.passport}
                                  onChange={handleChange}
                                  readOnly={isKok}
                        />
                        
                        </div>
                        <div className="contInput">
                    <p>Date of birth:</p>
                    <input 
                                  name="datebirth"
                                  type='date'
                                  onWheel={(e) => e.target.blur()}
                                  value={redBooking.addBooking.datebirth}
                                  onChange={handleChange}
                                  readOnly={isKok}
                        />
                        
                    </div>
                    <div className="contInput">
                    <p>Code of issuing State:</p>
                    <input 
                                  name="codestate"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={redBooking.addBooking.codestate}
                                  onChange={handleChange}
                                  readOnly={isKok}
                        />
                        
                    </div>
                        <div className="contInput">
                    <p>Location:</p>
                    <input 
                                  name="place"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={redBooking.addBooking.place}
                                  onChange={handleChange}
                                  readOnly={isKok}
                        />
                        
                        </div>
                    <div className="contInput">
                    <p>Comment:</p>
                    <input 
                                  name="comment"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={redBooking.addBooking.comment}
                                  onChange={handleChange}
                                  readOnly={isKok}
                        />
                        
                        </div>
                    <div className="contInput">
                    <p>Phone number: </p>
                        <input 
                                  name="phone"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={redBooking.addBooking.phone}
                                  onChange={handleChange}
                                  readOnly={isKok}
                        />
                    
                    </div>
                    <div className="contInput">
                    <p>Telegram: </p>
                        <input 
                                  name="tg"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={redBooking.addBooking.tg}
                                  onChange={handleChange}
                        />
                    
                    </div>
                    <div className="contInput">
                    <p>Helmets:</p>
                    <input 
                                  name="helmets"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={redBooking.addBooking.helmets}
                                  onChange={handleChange}
                                  readOnly={isKok}
                        />
                    
                    </div>
                    <div className="contInput">
                    <p>Date/time rent start:</p>
                    <input 
                                  name="dateStart"
                                  type='datetime-local'
                                  onWheel={(e) => e.target.blur()}
                                  value={redBooking.addBooking.dateStart}
                                  onChange={handleChange}
                                  readOnly={isKok}
                        />
                    
                    </div>
                    <div className="contInput">
                    <p>Date/time rent finish:</p>
                    <input 
                                  name="dateEnd"
                                  type='datetime-local'
                                  onWheel={(e) => e.target.blur()}
                                  value={redBooking.addBooking.dateEnd}
                                  onChange={handleChange}
                                  readOnly={isKok}
                        />
                    
                    </div>
                    <div className="contInput">
                    <p>Rent comment:</p>
                    <input 
                                  name="comRent"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={redBooking.addBooking.comRent}
                                  onChange={handleChange}
                                  readOnly={isKok}
                        />
                        
                        </div>
                    <div className="contInput">
                    <p>Rent days:</p>
                    <input 
                                  name="days"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={redBooking.addBooking.days}
                                  onChange={handleChange}
                                  readOnly={isKok}
                        />
                    
                    </div>
                    <div className="contInput">
                    <p>Price/day:</p>
                    <input 
                                  name="priceDay"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={redBooking.addBooking.priceDay}
                                  onChange={handleChange}
                                  readOnly={isKok}
                        />
                    
                    </div>
                    <div className="contInput">
                    <p>Price total:</p>
                    <input 
                                  name="totalPrice"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={redBooking.addBooking.totalPrice}
                                  onChange={handleChange}
                                  readOnly={isKok}
                        />
                    
                    </div>
                    <div style={{display:"flex", width:"101.5%"}}>
                    <div className="contInput" style={{width:"80%"}}>
                    <p>Deposit:</p>
                    <input 
                                  style={{width:"62%", textAlign:"end", paddingRight:"10%", boxSizing:"border-box"}}
                                  name="deposit"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={redBooking.addBooking.deposit}
                                  onChange={handleChange}
                        />
                    
                    </div>
                    <div className="contInput" style={{width:"25%"}}>
                    <select 
                                  style={{width:"100%", border:"none"}}
                                  name="depCurrency"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={redBooking.addBooking.depCurrency}
                                  onChange={handleChange}
                        >
                          <option value="" disabled hidden>Choose currency</option>
                          <option value="baht">baht</option>
                          <option value="baht online">baht online</option>
                          <option value="rub online">rub online</option>
                          <option value="rub cash">rub cash</option>
                          <option value="usd">usd</option>
                          <option value="usdt (crypto)">usdt (crypto)</option>
                          <option value="euro">euro</option>
                          <option value="passport">passport</option>
                          <option value="other">other</option>
                        </select>
                    </div>
                    </div>
                    <div className="contInput">
                    <p>Delivery:</p>
                    <input 
                                  name="delivery"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={redBooking.addBooking.delivery}
                                  onChange={handleChange}
                                  readOnly={isKok}
                        />
                    
                    </div>
                    {!isKok && <div className="contInput" style={{backgroundColor:"#abbcff"}}>
                    <p>How find out:</p>
                    <select 
                                  name="sourceAdd"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={redBooking.addBooking.sourceAdd}
                                  onChange={handleChange}
                        >
                          <option value="other">Other</option>
                          <option value="instagram">Instagram</option>
                          <option value="telegramm">Telegram</option>
                          <option value="vk">VK</option>
                          <option value="marketplace">Marketplace</option>
                          <option value="googleMaps">Google maps</option>
                          <option value="YandexMaps">Yandex maps</option>
                          <option value="advise">Advice</option>
                          <option value="webSite">Web site</option>
                          <option value="prolong">New contract</option>
                          <option value="instagram">Instagram</option>
                          <option value="agent">Agent</option>
                          <option value="ourcustomer">Our Customer</option>
                        </select>
                    
                    </div>}
                    <div>
                        {isKok ? <AgreementKok rent={redBooking} item={props.item} isKok={props.isKok}/>:
                        props.isKolcov ? <AgreementKolcov rent={redBooking} item={props.item} isKok={props.isKok}/>:
                        <Agreement rent={redBooking} item={props.item} isKok={props.isKok}/>
                        }
                       {!isKok && <button className="redButton" onClick={updateDocument}>Save</button>}
                    </div>
                  {!isKok &&   <div>
                        <button className="redButton" style={{backgroundColor:"#f09e9e", cursor:"pointer"}} onClick={setModal}>Удалить аренду</button>
                    </div>}
                    
            </div>
}
        </div>
    )
}

export default RedBooking