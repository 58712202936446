import './AllCount.css'
import { useEffect, useState } from 'react';
import ControlRedExp from './ControlRedExp';
import ControlAddExp from './ControlAddExp'
import { useNavigate } from 'react-router';
import iconDown from './iconDown.png'
import iconUp from './iconUp.png'

const moment = require('moment');
require('moment/locale/en-gb');

const ControlExp = props => {
    const [selectedRows, setSelectedRows] = useState([]);
    const toggleRowSelection = (index) => {
        setSelectedRows(prev => 
            prev.includes(index) ? prev.filter(i => i !== index) : [...prev, index]
        );
    };
    const [aCol, setACol] = useState(5)
    const moreCol = () => {
        setACol(aCol+10)
    }
    const lesCol = () => {
        setACol(5)
    }

    const navigate = useNavigate()

    const [expenses, setExpenses] = useState(null)
    //Для группировки по месяцам
    const [groupedExpenses, setGroupedExpenses] = useState([]);

    //Отображение расходов по месяцу
    const [expMonth, setExpMonth] = useState(null)
    

    //Для редактирования расходов
    const [exp, setExp] = useState(null)

//Все расходы по периоду
    const showExp = (exp) => {
      const sortedUpSort = [...exp.expenses].sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateB - dateA
        });
        setExpMonth(sortedUpSort)
    }
//Отображение окна редактирования
    const showRed = (item) => {
        setExp(item)
    }
//Показать окно добавления расходов
    const addExp = () => {
        setExp(null)
        setExpMonth(null)
    }

    useEffect(()=> {
        setExpenses(props.allExpenses)
    },[props])

    useEffect(() => {
      if (expenses) {
          // Функция для группировки расходов по дням
          const groupExpensesByDay = () => {
              const groupedByDay = {};
  
              expenses.forEach((expense) => {
                  const date = new Date(expense.date);
                  const day = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  
                  if (!groupedByDay[day]) {
                      groupedByDay[day] = [];
                  }
                  groupedByDay[day].push(expense);
              });
  
              const result = Object.keys(groupedByDay).map((day) => {
                  const totalAmount = groupedByDay[day].reduce((acc, expense) => acc + parseInt(expense.amount), 0);
  
                  return {
                      day,
                      expenses: groupedByDay[day], // Сохраняем все расходы за этот день
                      totalAmount,
                      date: new Date(day),
                  };
              });
  
              // Сортировка по дням от новых к старым
              result.sort((a, b) => b.date.getTime() - a.date.getTime());
  
              // Устанавливаем сгруппированные данные в состояние
              setGroupedExpenses(result);
          };
  
          groupExpensesByDay();
      }
  }, [expenses]);
  
      

    return (
        <div>
        <div className="contCount" style={{backgroundColor:"rgba(255, 0, 0, 0.1)"}}>
        <div className="contTable1">
        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
             <h3 style={{margin:"0", marginBottom:"10px", color:"red"}}>Experenses every day</h3>
             {expMonth && <button onClick={addExp} style={{width:"40px", height:"40px", borderRadius:"50%", border:"1.5px solid black",backgroundColor:"transparent", cursor:"pointer", fontSize:"28px"}}>+</button>}
        </div>
        {!props.isKok && 
        <div>
            <img src={iconDown} onClick={moreCol} alt="|" style={{width:"35px", height:"35px", marginBottom:"10px",  marginRight:"10px", cursor:"pointer"}}/>
            <img src={iconUp}  onClick={lesCol} alt="|" style={{width:"35px", height:"35px", marginBottom:"10px", cursor:"pointer"}}/>
        </div>}
        <table className='tableCount'>
        <thead >
          <tr >
              <th >Day</th> 
              <th>Total expenses</th> 
              {/* <th>Всего приходов</th> 
              <th>Чистая прибыль</th>      */}
          </tr>
        </thead>
        <tbody>
        {groupedExpenses.slice(0, aCol).map((group) => (
            <tr key={group.day} >
            <td onClick={()=>console.log(group)} >{moment(new Date(group.date)).format('DD MMMM YYYY')}</td>
            {/* <td>{new Date(group.date).toLocaleString('default', { month: 'long' })}</td> */}
            <td style={{cursor:"pointer"}} onClick={() => showExp(group)}>{group.totalAmount}</td>
                {/* <td>TEST</td>
                <td>TEST2</td> */}
            </tr>
        ))}
        </tbody>
      </table>
      </div>
      
      {expMonth ? 
            <div className="contTable2">
            <h3 style={{margin:"0", marginBottom:"10px"}}>Expenses for: </h3>
            <table className='tableCount2'>
            <thead>
                <tr style={{backgroundColor:"#e88989"}}>
                    <th></th>
                    <th>Date</th>
                    {!props.isKok && <th>Дата записи</th>}
                    <th>Amount</th>
                    <th>Item expense</th>
                    <th>Comment</th>
                    <th>Bike</th>
                    <th>Online</th>
                </tr>
            </thead>
            <tbody>
            {expMonth.map((item, ind) => (
                <tr key={ind} 
                style={{
                    cursor: "pointer",
                    backgroundColor: selectedRows.includes(ind) ? "yellow" : "inherit"
                }}>
                <td>
                    <input 
                        type="checkbox" 
                        onChange={() => toggleRowSelection(ind)} 
                        checked={selectedRows.includes(ind)} 
                        style={{width:"25px", height:"25px"}}
                    />
                </td>
                    <td onClick={()=> showRed(item)}>{moment(item.date).format("DD-MM-YYYY")}</td>
                    {!props.isKok && <td onClick={()=> showRed(item)}>{moment(parseInt(item.datewrite)).format("DD-MM-YYYY")}</td>}
                    <td onClick={()=> showRed(item)}>{item.amount}</td>
                    <td onClick={()=> showRed(item)}>{item.itemexp}</td>
                    <td onClick={()=> showRed(item)}>{item.comment}</td>
                    <td onClick={() => {
                        if (item.bike && item.bike !== "-") {
                            navigate(`/redAdd${item.bikeArt}`);
                        }
}}>{item.bike ? item.bike: "-" }</td>
                    <td onClick={()=> showRed(item)}>{item.cash ? "Yes":"No"}</td>
                </tr>
            ))}
             </tbody>
             </table>

            {exp && <ControlRedExp exp={exp} user={props.user} allBikes={props.allBikes}/>}    


            </div>:
            <div className="contTable2" style={{paddingTop:"15px"}}>
             <ControlAddExp user={props.user} allBikes={props.allBikes}/>
            </div>
        }
            </div>
        </div>
    )
}

export default ControlExp