import './AllCount.css'
import { useEffect, useState } from 'react';
import iconDown from './iconDown.png'
import iconUp from './iconUp.png'



const moment = require('moment');
require('moment/locale/en-gb');

const TotalCount = props => {

    const [expenses, setExpenses] = useState(null)
    const [income, setIncome] = useState(null)

    const [groupArr, setGroupArr] = useState(null)

    //количество строк

    const [aCol, setACol] = useState(5)
    const moreCol = () => {
        setACol(aCol+10)
    }
    const lesCol = () => {
        setACol(5)
    }

    useEffect(() => {
        const handleFocusIn = (event) => {
            if (event.target.tagName === "INPUT" || event.target.tagName === "TEXTAREA") {
                document.querySelector("meta[name=viewport]").setAttribute(
                    "content",
                    "width=device-width, initial-scale=0.4, maximum-scale=0.6"
                );
            }
        };

        const handleFocusOut = () => {
            document.querySelector("meta[name=viewport]").setAttribute(
                "content",
                "width=device-width, initial-scale=0.4"
            );
        };

        document.addEventListener("focusin", handleFocusIn);
        document.addEventListener("focusout", handleFocusOut);

        return () => {
            document.removeEventListener("focusin", handleFocusIn);
            document.removeEventListener("focusout", handleFocusOut);
        };
    }, []);

    useEffect(() => {
        setExpenses(props.allExpenses);
        setIncome(props.allIncome);
    }, [props]);

    useEffect(() => {
        window.scrollTo(0, 0);

    }, []);
    
    useEffect(() => {
        if (!expenses || !income) return; // Ждём, пока данные загрузятся
    
        const groupDataByDate = () => {
            const groupedData = {};
    
            // Группируем только расходы, у которых cash === true
            expenses
                .filter(expense => !expense.cash)
                .forEach(expense => {
                    const { date, amount } = expense;
                    if (!groupedData[date]) {
                        groupedData[date] = { 
                            day: date, 
                            totalExpenses: 0, 
                            totalIncome: 0, 
                            expenses: [], 
                            income: [] 
                        };
                    }
                    groupedData[date].totalExpenses += parseInt(amount);
                    groupedData[date].expenses.push(expense);
                });
    
            // Группируем только доходы, у которых cash === true
            income
                .filter(income => !income.cash)
                .forEach(income => {
                    const { date, amount } = income;
                    if (!groupedData[date]) {
                        groupedData[date] = { 
                            day: date, 
                            totalExpenses: 0, 
                            totalIncome: 0, 
                            expenses: [], 
                            income: [] 
                        };
                    }
                    groupedData[date].totalIncome += parseInt(amount);
                    groupedData[date].income.push(income);
                });
    
            // Преобразуем объект в массив, сортируем по дате (ближайшие даты первыми) и записываем в стейт
            console.log("Результат объединения", Object.values(groupedData));
            const sortedData = Object.values(groupedData).sort((b, a) => a.day.localeCompare(b.day));
            setGroupArr(sortedData);
        };
    
        groupDataByDate();
    }, [expenses, income]);
    



  
    return (
        <div >
        <div className="contCount" >
        <div className="contTableAll" style={{width:"100%"}}>
        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
             <h3 style={{margin:"0", marginBottom:"10px", color:"green"}} >Total every day</h3>
            
        {!props.isKok && 
        <div>
            <img src={iconDown} onClick={moreCol} alt="|" style={{width:"35px", height:"35px", marginBottom:"10px",  marginRight:"10px", cursor:"pointer"}}/>
            <img src={iconUp}  onClick={lesCol} alt="|" style={{width:"35px", height:"35px", marginBottom:"10px", cursor:"pointer"}}/>
        </div>}
        </div>
        <table className='tableCount'>
        <thead style={{backgroundColor:"#c0ebcc"}}>
          <tr >
              <th>Date</th> 
              <th>All expenses</th> 
              <th>All incomes</th> 
              <th>Total</th>     
          </tr>
        </thead>
        <tbody>
            {groupArr && groupArr.slice(0, aCol).map ((item, ind)=>
                    <tr key={ind} style={{cursor:"pointer"}}>
                        <td>{moment(item.day).format("DD-MM-YYYY")}</td>
                        <td>{item.totalExpenses}</td>
                        <td>{item.totalIncome}</td>
                        <td>{item.totalIncome - item.totalExpenses}</td>
                    </tr>
            )}
        </tbody>
      </table>
      </div>
      
            </div>
        </div>
    )
}

export default TotalCount