import { useEffect, useState } from 'react';
import './ListExpenses.css'

const moment = require('moment');
require('moment/locale/en-gb');

const ExpIncomeBike = props => {
 
    const [allExp, setAllExp] = useState(null)
    const [allIncome, setAllIncome] = useState(null)

    useEffect(()=> { 
      if (props.allExpenses) {
          console.log(props.allExpenses)
        const bikesData = props.allExpenses
        const sortedUpSort = [...bikesData].sort((b, a) => {
            const dateA = !a.date ? new Date('9999-12-31') : new Date(a.date);
            const dateB = !b.date  ? new Date('9999-12-31') : new Date(b.date);
            return dateA - dateB;
        })
        setAllExp(sortedUpSort.filter(item => item.bikeId === props.id));
      }
      // eslint-disable-next-line
  },[props])

  useEffect(()=> { 
    if (props.allIncome) {
        console.log(props.allIncome)
      const bikesData = props.allIncome
      const sortedUpSort = [...bikesData].sort((b, a) => {
          const dateA = !a.date ? new Date('9999-12-31') : new Date(a.date);
          const dateB = !b.date  ? new Date('9999-12-31') : new Date(b.date);
          return dateA - dateB;
      })
      setAllIncome(sortedUpSort.filter(item => item.bikeId === props.id && item.itemexp !== "Rent" && item.itemexp !== "Extend" && item.itemexp !== "New contract"));
    }
    // eslint-disable-next-line
},[props])

    return (
      <div >
    <h2 style={{margin:"0", marginBottom:"20px"}}>Дополнительные расходы по байку</h2>
      <table className='tableExp'>
        <thead >
          <tr >
              <th>Дата расхода</th> 
              <th>Дата записи</th> 
              <th>Сумма расхода</th> 
              <th>Статья расхода</th> 
              <th>Комментарий</th>   
          </tr>
        </thead>
        <tbody>
          {allExp && allExp.map((item, ind)=> 
            <tr>
                <td>{item.date}</td>
                <td>{moment(parseInt(item.datewrite)).format("DD-MM-YYYY")}</td>
                <td>{item.amount}</td>
                <td>{item.itemexp}</td>
                <td>{item.comment}</td>
            </tr>
          )}
        </tbody>
      </table>
      <h2 style={{marginBottom:"15px", marginTop:"20px"}}>Дополнительные доходы по байку</h2>
      <table className='tableExp'>
        <thead style={{backgroundColor:"#c0ebcc"}}>
          <tr >
              <th>Дата дохода</th> 
              <th>Дата записи</th> 
              <th>Сумма дохода</th> 
              <th>Статья дохода</th> 
              <th>Комментарий</th>   
          </tr>
        </thead>
        <tbody>
          {allExp && allIncome.map((item, ind)=> 
            <tr>
                <td>{item.date}</td>
                <td>{moment(parseInt(item.datewrite)).format("DD-MM-YYYY")}</td>
                <td>{item.amount}</td>
                <td>{item.itemexp}</td>
                <td>{item.comment}</td>
            </tr>
          )}
        </tbody>
      </table>

      </div>
    );
  }

export default ExpIncomeBike