
// import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { initializeApp } from 'firebase/app';
import {doc, updateDoc, getFirestore, deleteDoc} from 'firebase/firestore'; // Firebase Modular SDK импорты
import './AddBike.css'
import { useEffect, useState } from 'react'
import firebaseConfig from '../firebaseConfig';

const IcomControlRed = props => {
    //Подключаемся к FireBase
    const app = initializeApp(firebaseConfig);
    // const storage = getStorage(app);
    const db = getFirestore(app);

    const [redExpenses, setRedExpenses] = useState(null)
    const [searchResults, setSearchResults] = useState([]);

    useEffect(() => {
        setRedExpenses(props.exp)
      }, [props]);
    

    //Функция изменения значения
    const handleChange = (event) => {
      const { name, value } = event.target;
      
      setRedExpenses((prevData) => ({
          ...prevData,
          [name]: value,
          ...(name === "bike" && (value === "" || value === "-") ? { bikeId: "" } : {}) // Если bike пустой, обнуляем bikeId
      }));
  
      if (name === "bike") {
          const results = Object.values(props.allBikes).filter(bike => 
              bike.addBike.regNumber.includes(value)
          );
          setSearchResults(results);
      }
  };
  

      const handleSelectBike = (bike) => {
        console.log(bike)
        setRedExpenses((prevState) => ({
            ...prevState,
            bikeArt: bike.addBike.id,
            bikeId: bike.id,
            bike: bike.addBike.brand + " " + bike.addBike.model + " (" + bike.addBike.regNumber+")"
        }));
        setSearchResults([]);
    };
        
  const updateDocument = async () => {
    console.log("ID", props.exp)
    try {
      const documentRef = doc(db, 'allincome', props.exp.id);
      await updateDoc(documentRef, redExpenses);
      window.location.reload()
    } catch (error) {
      console.error('Ошибка при обновлении документа:', error);
      return false;
    }
}
const deleteDocument = async () => {
    const isConfirmed = window.confirm("You really want to delete it?");
    if (isConfirmed) {
        console.log("User clicked Yes");
    
        try {
            const documentRef = doc(db, "allincome", props.exp.id); 
            await deleteDoc(documentRef);
            window.location.reload();

        } catch (error) {
            console.error("Ошибка при удалении документа из базы данных", error);
        }
}
};
    return (
        <div >
            {redExpenses &&
            <div className="contRedAdd" style={{backgroundColor:"#d1a7b8", paddingTop:"4%"}} onClick={()=> setSearchResults([])} >
                <h3 style={{margin:"0", marginBottom:"10px"}}>Editing income</h3>
                <div className="contInput">
                     <p>Date:</p>
                    <input 
                                  name="date"
                                  type='date'
                                  onWheel={(e) => e.target.blur()}
                                  value={redExpenses.date}
                                  onChange={handleChange}
                        />
                    </div>
                    <div className="contInput">
                     <p>Amount:</p>
                    <input 
                                  name="amount"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={redExpenses.amount}
                                  onChange={handleChange}
                        />
                    </div>
                    <div className="contInput">
                     <p>Item income:</p>
                    <select 
                        name="itemexp" 
                        value={redExpenses.itemexp} 
                        onChange={handleChange}
                    >
                        <option value="Other">Other</option>
                        <option value="Rent">Rent</option>
                        <option value="New contract">New contract</option>
                        <option value="Extend">Extend</option>
                        <option value="Gas">Gas</option>
                        <option value="Damage">Damage</option>
                        <option value="Help customer">Help customer</option>
                    </select>
                </div>
                    <div className="contInput">
                     <p>Comment:</p>
                    <input 
                                  name="comment"
                                  type='text'
                                  onWheel={(e) => e.target.blur()}
                                  value={redExpenses.comment}
                                  onChange={handleChange}
                        />
                    </div>
                    <div className="contInput" style={{position:"relative"}}>
                    <p>Bike:</p>
                    <input 
                        name="bike"
                        type='text'
                        onWheel={(e) => e.target.blur()}
                        value={redExpenses.bike}
                        onChange={handleChange}
                    />
                    {searchResults.length > 0 && (
                        <div className="searchResults">
                            {searchResults.map((bike) => (
                                <div 
                                    key={bike.id} 
                                    className="searchItem" 
                                    onClick={() => handleSelectBike(bike)}
                                >
                                    {bike.addBike.brand} {bike.addBike.model} ({bike.addBike.regNumber})
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                    <div className="contInputExpCheck">
                    <p>Online:</p>
                    <input 
                        name="cash"
                        type="checkbox"
                        onWheel={(e) => e.target.blur()}
                        checked={redExpenses.cash} 
                        onChange={(e) => setRedExpenses(prev => ({ ...prev, cash: e.target.checked }))}
                        style={{ width: "25px", height: "25px" }}
                    />
                </div>
                    <div>
                        <button className="redButton" onClick={updateDocument}>Save</button>
                        <button className="redButton" style={{backgroundColor:"#f09e9e", cursor:"pointer"}} onClick={deleteDocument}>Delete income</button>
                    </div>
                    
            </div>
}
        </div>
    )
}

export default IcomControlRed