import { useEffect, useState } from "react"
import '../css/AllCount.css'
// import {doc,deleteDoc,getFirestore } from 'firebase/firestore'
// import { initializeApp } from 'firebase/app';
// import firebaseConfig from './firebaseConfig'

const Deposits = props => {

    const [allDep, setAllDep] = useState(null)
    const [showDep, setShowDep] = useState(false)
//Тоже для не нужных документов оставляю на всякий случай
    // const app = initializeApp(firebaseConfig);
    // const storage = getStorage(app);
    // const db = getFirestore(app);


    useEffect(()=>{
        if (props.allBike && props.allBooking) {
            filter()
        }
    }, [props])

    const filter = () => {
        // Шаг 1: Фильтруем уникальные записи с самой поздней dateEnd
        const uniqueBookings = Object.values(
          props.allBooking.reduce((acc, booking) => {
            const id = booking.addBooking.id;
            const currentDate = new Date(booking.addBooking.dateEnd);
      
            if (!acc[id] || new Date(acc[id].addBooking.dateEnd) < currentDate) {
              acc[id] = booking;
            }
      
            return acc;
          }, {})
        );
      
        // Шаг 2: Фильтруем по условиям allBike.id и available === false
        const filteredBookings = uniqueBookings.filter(booking =>
          props.allBike.some(bike => bike.id === booking.addBooking.id && bike.addBike.available === false)
        );
      
        // setAllDep(filteredBookings);
        join(filteredBookings)
      };

      const join = (arr) => {
        const result = Object.values(
          arr.reduce((acc, item) => {
            const currency = item.addBooking.depCurrency;
            const deposit = Number(item.addBooking.deposit);
      
            if (!acc[currency]) {
              acc[currency] = { depCurrency: currency, depNumber: 0, depSumm: 0 };
            }
      
            acc[currency].depNumber += 1;
            if (!isNaN(deposit)) {
              acc[currency].depSumm += deposit;
            }
      
            return acc;
          }, {})
        );
        const order = [
          "baht",
          "baht online",
          "rub online",
          "rub cash",
          "usd",
          "usdt (crypto)",
          "euro",
          "passport",
          "other"
        ];
        const sortedAllDep = order.reduce((acc, category) => {
          const items = result.filter(item => item.depCurrency === category);
          return [...acc, ...items];
        }, []);
      
        console.log(sortedAllDep)
        setAllDep(sortedAllDep);
      };
      //Поиск пустых значений, тоже оставлю на всякий случай, чтобы искать в аренде не нужные документы
    //   const findOut = arr => {
    //     const nonNumericItems = arr.filter(item => {
    //       const totalPrice = item.addBooking.totalPrice;
    //       if (totalPrice === "") return true;
    //       return isNaN(Number(totalPrice));
    //     });
        
    //     console.log(nonNumericItems);
    //   };
      
      
    //На всякий случай оставляю для удаления нужных документов
    //   const deleteDocument = async () => {
    //     try {
    //       const documentRef = doc(db, "booking", "0QUWAZ6IBpYGgdk49Kwx"); 
    //       await deleteDoc(documentRef);
    //       window.location.reload();
    //     } catch (error) {
    //       console.error("Ошибка при удалении документа из базы данных", error);
    //     }
    //   };

      if (showDep) {
      return (
        allDep && allDep.length > 0 ? (
          <div style={{paddingLeft:"3%", paddingRight:"3%"}}>
              <h2>Все депозиты</h2>
              <button onClick={()=> setShowDep(false)} style={{height:"40px", fontWeight:"bold", marginBottom:"20px", cursor:"pointer"}}>Скрыть депозиты</button>
            <table className="tableCount">
              <thead>
                <tr>
                  <th>Вид депозита</th>
                  <th>Количество</th>
                  <th>Сумма</th>
                </tr>
              </thead>
              <tbody>
                {allDep.map((group) => (
                  <tr key={group.depCurrency}>
                    <td>{group.depCurrency || "-"}</td>
                    <td>{group.depNumber !== undefined ? group.depNumber : "-"}</td>
                    <td>{group.depSumm !== undefined ? group.depSumm : "-"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div>Нет данных</div>
        )
      );
        }
        else {
            return (
                <div style={{paddingLeft:"3%", paddingRight:"2%", paddingTop:"20px"}}>
                    <button onClick={()=> setShowDep(true)} style={{height:"40px", fontWeight:"bold", cursor:"pointer"}}>Показать все депозиты</button>
                </div>
            )
        }
      
}
export default Deposits