import '../css/AllCount.css'
// import { useEffect, useState } from 'react';
import AllExpenses from './AllExpenses';
import AllIcoms from './AllIcoms';
import Total from './Total';
import Deposits from './Deposits';

const AllCount = props => {

    return (
        <div style={{minWidth:"1010px"}}>
            <h2 style={{marginLeft:"30px" }}>Доходы/Расходы</h2>
            <Total allBike={props.allBike} allBooking={props.allBooking} allExpenses={props.allExpenses}/>
            <Deposits allBike={props.allBike} allBooking={props.allBooking}/>
            <AllExpenses allExpenses={props.allExpenses} user={props.user}/>
            <AllIcoms allBooking={props.allBooking} allBike={props.allBike}/>
        </div>
    )
}

export {AllCount}